<template>
  <div>
    <div class="main">
      <div class="header">
        <div class="content-block">
          <section style="display: flex;">
            <img src="../assets/logo.png" style="max-width: 100px;">
            <h1 style="margin-left: 20px;">Groom Notes</h1>
          </section>

          <section>
            <nav class="main-nav">
              <a href="#" v-scroll-to="'#about-block'">About</a>
              <a href="#" v-scroll-to="'#download-block'">Download</a>
              <a href="#" v-scroll-to="'#contact-block'">Contact</a>
            </nav>
          </section>
        </div>
      </div>

      <div style="height: 100px"><br></div>

      <div class="content-block">
        <section>
          <p style="font-size: 30px; background: #ffffffd1;">Illustrations and short descriptions for a quick reference that is sure to come in handy for newbies and seasoned groomers alike!</p>
        </section>
      </div>

      <div id="about-block" class="content-block" style="display: none;">
        <section style="background: #ffffffd1;">
          <h2>Some Title Here</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
        </section>

        <section>
          <img src="../assets/screenshot-list.png">
        </section>
      </div>

      <div class="content-block" style="display: none;">
        <section>
          <img src="../assets/screenshot-detail.png">
        </section>

        <section style="background: #ffffffd1;">
          <h2>Some Title Here</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
        </section>
      </div>

      <div id="download-block" class="slick-block">
        <Thumbnails />
        <!-- <h2>Download the App</h2> -->
        <img src="../assets/button-appstore.png" class="app-store-link">
      </div>

      <div id="contact-block" class="contact-block">
        <ContactForm />
      </div>
    </div>

    <div class="filler-block">
      <!-- to show off the dog sketch -->
    </div>
    <footer>
      <p>© {{ year }} These Great Apps, LLC</p>
    </footer>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import Thumbnails from '@/components/Thumbnails.vue'
import ContactForm from '@/components/ContactForm.vue'

export default {
  name: 'Home',
  components: {
    HelloWorld,
    Thumbnails,
    ContactForm
  },

  data() {
    return {
      year: new Date().getFullYear()
    }
  }
}
</script>

<style type="text/css" scoped="true">
h1 {
  font-size: 30px;
}

p {
  font-size: 20px;
}

.main {
  background: #fffffff5;
  /*background: #fddfca;*/
  position: relative;
}

.header {
  background: #d4e7f0;
  position: fixed;
  width: 100%;
  z-index: 2;
  border-bottom: 2px solid #a5b9c3;
}

.content-block {
  display: flex;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto;
  padding:  40px 20px;
}

.header .content-block {
  padding: 0 20px;
}

@media only screen and (max-width: 650px) {
  .content-block {
    flex-direction: column;
  }
}

.slick-block {
  background: #96afb8;
  border-top: 2px solid #768d95;
  border-bottom: 2px solid #768d95;
  overflow: hidden;
  min-height: 300px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-block {
  background: #d4e7f0c4;
  border-bottom: 2px solid #c7dae3;
  min-height: 300px;
  position: relative;
  display: flex;
  flex-direction: row;
}

.filler-block {
  min-height: 700px;
  position: relative;
}

@media only screen and (max-width: 650px) {
  .filler-block {
    display: none;
  }
}

.app-store-link {
  max-width: 200px;
}

.content-block > section {
  flex:  1;
}

.content-block img {
  max-width: 200px;
}

.main-nav {
  display: flex;
  justify-content: flex-end;
}

.main-nav > a {
  padding-left: 10px;
  text-decoration: none;
  color: #677b83;
  font-weight: bold;
  font-size: 18px;
}
.main-nav > a:hover {
  text-decoration: underline;
}
</style>
