<template>
  <div>
    <div v-if="thumbnails">
      <div v-for="(thumbnail, index) of thumbnails" class="thumbnail" :style="`left: ${thumbnail.left}px; top: ${thumbnail.top}px;`">
        <div v-rellax="{speed: thumbnail.speed}">
          <img :src="thumbnail.url" :height="thumbnail.height" :width="thumbnail.width">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dogData from "@/components/dogData.json"

export default {
  name: 'Thumbnails',
  data() {
    return {
      thumbnails: null
    }
  },

  mounted() {
    this.loadThumbs()
  },

  methods: {
    async loadThumbs() {
      let thumbnailStrings = []

      dogData.map(dogSection => {
        dogSection.data.map(dog => {
          if (
            dog.thumbnailUrl !== 'https://dl.airtable.com/.attachmentThumbnails/9111bbf9a91053369fd56e122b0498d1/8d781c42'
            && dog.thumbnailUrl !== 'https://dl.airtable.com/.attachmentThumbnails/8852ca9b41f0438a41d57a739ba37564/81f46b01'
          ) {
            thumbnailStrings.push(dog.thumbnailUrl)
          }
        })
      })

      thumbnailStrings = thumbnailStrings
                            .concat(thumbnailStrings)
                            .concat(thumbnailStrings)
                            // .concat(thumbnailStrings)
                            // .concat(thumbnailStrings)

      this.thumbnails = thumbnailStrings.map(thumbnailUrl => {
        const thumbSize = `${this.randomIntFromInterval(30, 200)}px`
        return {
          height: thumbSize,
          width: thumbSize,
          left: this.randomIntFromInterval(0, (window.innerWidth - 200)),
          top: this.randomIntFromInterval(0, 3000),
          speed: this.randomIntFromInterval(0, 10),
          url: thumbnailUrl
        }
      })
    },

    randomIntFromInterval(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min)
    }
  }
}
</script>

<style type="text/css" scoped="true">
.thumbnail,
.thumbnail img {
  position: absolute;
  max-height: 100px;
  max-width: 100px;
  opacity: 0.5;
}
</style>
