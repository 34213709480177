<template>
  <div class="contact-wrapper">
    <h2>Contact Us</h2>
    <form name="contact" method="POST" data-netlify="true" data-netlify-honeypot="bot-field">
      <input type="hidden" name="form-name" value="contact">
      <p>
        <label><input placeholder="Name:" class="form-field" type="text" name="name" /></label>
      </p>
      <p>
        <label><input placeholder="Email:" class="form-field" type="email" name="email" /></label>
      </p>
      <p>
        <label><textarea placeholder="Message:" class="form-field" name="message"></textarea></label>
      </p>
      <p>
        <button class="btn" type="submit">Submit</button>
      </p>
    </form>
  </div>
</template>

<script>
export default {
  name: 'ContactForm'
}
</script>

<style type="text/css" scoped="true">
form {
  width: 400px;
  margin: 0 auto;
}

.form-field,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  border-radius: 0.5rem;
  border: 0.1rem solid rgba(14,30,37,.054);
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #0e1e25;
  display: block;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  /*font-family: Mulish,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;*/
  font-size: 16px;
  font-size: 1rem;
  font-weight: 500;
  line-height: 24px;
  line-height: 1.5rem;
  margin: 1rem 0;
  padding: 0.4rem 0.9rem;
  resize: none;
  -webkit-transition: -webkit-box-shadow .2s ease-in-out;
  width: 100%;
}

textarea {
  height: 104px;
  height: 6.5rem;
}

.btn {
  background-color: #96afb8;
  min-width: 300px;
  width: 100%;
  border-radius: 5px;
  border: none;
  color: #FFFFFF;
  padding: 15px 32px;
  text-align: center;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  margin: 16px 0 !important;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  font-weight: bold;
}

.btn:hover {
  background: #bcd6df;
}

.contact-wrapper {
  margin: 0 auto;
}
</style>
